import React, { memo } from "react";
import styled from "styled-components";
import { AppBar, CssBaseline, Tab, Tabs } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { hasAccessLevel, isSuperUser } from "../util/active_user_context";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LockIcon from "@mui/icons-material/Lock";
import AddchartIcon from "@mui/icons-material/Addchart";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import WarningIcon from "@mui/icons-material/Warning";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { AccessLevel } from "../generated/gql/graphql";

const StyledScaffold = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
    flex-flow: column;
    overflow: hidden;

    @media print {
        display: block;
    }
`;

const StyledBody = styled.div`
    flex: 1;
    position: relative;
    overflow: hidden;

    @media print {
        display: block;
    }
`;

export default memo<{ children: React.ReactNode }>(({ children }) => {
    const location = useLocation();

    let page: String = "tickets";
    let pageRight: String = "";

    if (location.pathname.startsWith("/health")) pageRight = "health";

    if (location.pathname.startsWith("/statistics-processing")) pageRight = "statistics-processing";

    if (location.pathname.startsWith("/reports")) page = "reports";
    if (location.pathname.startsWith("/nets-transaction")) page = "nets-transactions";
    if (location.pathname.startsWith("/complaints")) page = "complaints";
    if (location.pathname.startsWith("/administrators")) page = "administrators";

    return (
        <StyledScaffold>
            <CssBaseline />
            <AppBar position={"static"} color={"primary"} style={{ flexDirection: "row", alignItems: "center" }}>
                <Link to={"/"}>
                    <img
                        src={"/logo_dark.png"}
                        width={184}
                        height={32}
                        style={{ margin: "8px 32px", marginTop: "16px" }}
                    />
                </Link>

                <Tabs
                    value={pageRight ? "" : page}
                    textColor={"inherit"}
                    color={"inherit"}
                    indicatorColor={"secondary"}
                >
                    <Tab label={"Billetter"} value={"tickets"} component={Link} to={"/"} />
                    <Tab
                        label={"Klager"}
                        value={"complaints"}
                        component={Link}
                        to={"/complaints"}
                        icon={<WarningIcon />}
                        iconPosition={"start"}
                    />
                    <Tab
                        label={"Kortbetalinger"}
                        value={"nets-transactions"}
                        component={Link}
                        to={"/nets-transactions"}
                        icon={<MonetizationOnIcon />}
                        iconPosition={"start"}
                    />
                    <Tab
                        iconPosition="start"
                        icon={<AddchartIcon />}
                        label={"Eksport"}
                        value={"reports"}
                        component={Link}
                        to={"/reports"}
                    />
                    {hasAccessLevel(AccessLevel.Project) ? (
                        <Tab
                            iconPosition="start"
                            icon={<PeopleAltIcon />}
                            label={"Tilganger"}
                            value={"administrators"}
                            component={Link}
                            to={"/administrators"}
                        />
                    ) : null}
                </Tabs>
                <div style={{ flex: "1" }} />
                <Tabs value={pageRight} textColor={"inherit"} color={"inherit"} indicatorColor={"secondary"}>
                    <Tab
                        label={"Brukerhåndbok"}
                        iconPosition={"start"}
                        icon={<HelpOutlineIcon />}
                        href={"https://kolumbus.atlassian.net/wiki/spaces/SV2/pages/3376611398/Reisefrihet+adminportal"}
                        target={"_blank"}
                    />
                    {isSuperUser() ? <Tab label={"Health"} value={"health"} component={Link} to={"/health"} /> : null}
                    {isSuperUser() ? (
                        <Tab
                            label={"Stats - processing"}
                            value={"statistics-processing"}
                            component={Link}
                            to={"/statistics-processing"}
                        />
                    ) : null}

                    <Tab label={"Logg ut"} icon={<LockIcon />} iconPosition={"start"} href={"/logout"} />
                </Tabs>
            </AppBar>
            <StyledBody>{children}</StyledBody>
        </StyledScaffold>
    );
});
