import {
    Button,
    ButtonGroup,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    ListSubheader,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import React, { FormEvent, useState } from "react";
import { ISODate } from "./statistics/StatisticsProcessingPage";
import { DateTime, DateTimeUnit, DurationLike } from "luxon";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { usePageTitle } from "../util/page_title";

export function ReportsPage() {
    const [type, setType] = useState<ReportType>(ReportType.activeCustomers);
    const [timeRange, setTimeRange] = useState<TimeRange>(buildTimeRangeForThis("month"));
    usePageTitle("Eksport");

    function handleSubmit(event: FormEvent) {
        event.preventDefault();
        event.stopPropagation();

        let url = "/api/admin/reports/" + type;
        url += "?from=" + encodeURIComponent(timeRange[0]);
        url += "&to=" + encodeURIComponent(timeRange[1]);

        window.open(url, "_blank");
    }

    return (
        <div style={{ width: "100%", height: "100%", overflowY: "scroll" }}>
            <form
                onSubmit={handleSubmit}
                style={{
                    width: "100%",
                    padding: "20px",
                    maxWidth: "500px",
                    margin: "40px auto",
                }}
            >
                <Typography variant={"h4"} style={{ marginBottom: "40px" }}>
                    Eksport
                </Typography>

                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <ReportTypeField value={type} onChange={setType} />
                    </Grid>
                    <Grid item md={12}>
                        <TimeRangeField value={timeRange} onChange={setTimeRange} />
                    </Grid>
                </Grid>

                <div style={{ height: "24px" }} />

                <Button type={"submit"} variant={"contained"} size={"large"} startIcon={<CloudDownloadIcon />}>
                    Hent data
                </Button>
            </form>
        </div>
    );
}

enum ReportType {
    ticketLegs = "ticket-legs",
    activeCustomers = "active-customers",
    questionnairesTicketLegsDetected = "questionnaires-ticket-legs-detected",
    questionnairesTicketLegsDetectedV2 = "questionnaires-ticket-legs-detected-v2",
    questionnairesNoLegsDetected = "questionnaires-no-legs-detected",
}

function ReportTypeField(props: { value: ReportType; onChange: (newValue: ReportType) => void }) {
    return (
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.value}
                label="Type"
                onChange={(e) => props.onChange(e.target.value as ReportType)}
            >
                <MenuItem value={ReportType.ticketLegs}>
                    <ListItemText
                        primary={"Reiser"}
                        secondary={
                            <>
                                En rad for hver ombordstigning/Trip-Id.
                                <br />
                                Gå-på trykk uten reise detektert vises som egen rad.
                            </>
                        }
                    />
                </MenuItem>
                <ListSubheader>Spørreskjemaer</ListSubheader>
                <MenuItem value={ReportType.questionnairesTicketLegsDetected}>
                    <ListItemText primary={"Fornøydhet Runde 1"} />
                </MenuItem>
                <MenuItem value={ReportType.questionnairesTicketLegsDetectedV2}>
                    <ListItemText primary={"Fornøydhet Runde 2"} />
                </MenuItem>
                <MenuItem value={ReportType.questionnairesNoLegsDetected}>
                    <ListItemText primary={"Ingen reise detektert"} />
                </MenuItem>
                <Divider />

                <ListSubheader>Tidslinjer (En rad for hver dag)</ListSubheader>
                <MenuItem value={ReportType.activeCustomers}>Antall aktive brukere</MenuItem>
            </Select>
        </FormControl>
    );
}

function TimeRangeField(props: { value: TimeRange; onChange: (newValue: TimeRange) => void }) {
    function buildButton(value: TimeRange, label: React.ReactNode) {
        const isActive = props.value[0] == value[0] && props.value[1] == value[1];
        return (
            <Button variant={isActive ? "contained" : undefined} onClick={() => props.onChange(value)}>
                {label}
            </Button>
        );
    }

    return (
        <Grid container columnSpacing={2}>
            <Grid item md={12}>
                <Grid container>
                    <Typography variant={"subtitle1"}>Periode</Typography>
                </Grid>
            </Grid>
            <Grid item md={6}>
                <DateField
                    label={"Fra"}
                    value={props.value[0]}
                    onChange={(newValue) => props.onChange([newValue, props.value[1]])}
                />
            </Grid>
            <Grid item md={6}>
                <DateField
                    label={"Til"}
                    value={props.value[1]}
                    onChange={(newValue) => props.onChange([props.value[0], newValue])}
                />
            </Grid>

            <Grid item md={12}>
                <Typography variant={"subtitle2"} style={{ marginTop: "8px" }}>
                    Hurtigvalg:
                </Typography>
                <ButtonGroup color={"primary"} size={"small"}>
                    {buildButton(buildTimeRangeForThis("day"), "I dag")}
                    {buildButton(buildTimeRangeForThis("day", { day: 1 }), "I går")}
                </ButtonGroup>
                <div style={{ height: "4px" }} />
                <ButtonGroup color={"primary"} size={"small"}>
                    {buildButton(buildTimeRangeForThis("week", { week: 1 }), "Forrige uken")}
                    {buildButton(buildTimeRangeForThis("week"), "Denne uken")}
                </ButtonGroup>
                <div style={{ height: "4px" }} />
                <ButtonGroup color={"primary"} size={"small"}>
                    {buildButton(
                        buildTimeRangeForThis("month", { month: 1 }),
                        DateTime.now().minus({ month: 1 }).setLocale("nb").monthLong,
                    )}
                    {buildButton(buildTimeRangeForThis("month"), DateTime.now().setLocale("nb").monthLong)}
                </ButtonGroup>
                <div style={{ height: "4px" }} />
                <ButtonGroup color={"primary"} size={"small"}>
                    {buildButton(buildTimeRangeForThis("year", { year: 1 }), DateTime.now().minus({ year: 1 }).year)}
                    {buildButton(buildTimeRangeForThis("year"), DateTime.now().year)}
                </ButtonGroup>
            </Grid>
        </Grid>
    );
}

function DateField(props: { label: React.ReactNode; value: ISODate; onChange: (newValue: ISODate) => void }) {
    return (
        <DatePicker
            format="dd.LL.yyyy"
            label={props.label}
            value={DateTime.fromISO(props.value)}
            onChange={(newValue) => props.onChange((newValue as DateTime).toISODate()!)}
        />
    );
}

type TimeRange = readonly [ISODate, ISODate];

function buildTimeRangeForThis(unit: DateTimeUnit, minus?: DurationLike): TimeRange {
    let from = DateTime.now();
    if (minus) from = from.minus(minus);

    return [from.startOf(unit).toISODate()!, from.endOf(unit).toISODate()!];
}
