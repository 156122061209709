import React, { useContext } from "react";
import { AccessLevel } from "../generated/gql/graphql";

export type ActiveUser = {
    email: string;
    accessLevel: AccessLevel;
};

let activeUser: ActiveUser | null | undefined = undefined;
const accessLevels = [AccessLevel.Developer, AccessLevel.Project, AccessLevel.Support];

export async function fetchActiveUser(): Promise<ActiveUser | null> {
    let response = await fetch("/api/auth/me", { redirect: "manual" });

    const isUnauthorized = response.status === 401;
    if (isUnauthorized) return null;

    const isOk = response.status >= 200 && response.status < 300;
    if (!isOk) {
        throw Error("Unexpected response status code: " + response.status);
    }

    const user = await response.json();
    return user as ActiveUser;
}

export function setActiveUser(user: ActiveUser | null) {
    activeUser = user;
}

export function getActiveUser(): ActiveUser | null | undefined {
    return activeUser;
}

export const ActiveUserContext = React.createContext<ActiveUser | null>(null);

export function isSuperUser(): boolean {
    return hasAccessLevel(AccessLevel.Developer);
}

export function hasAccessLevel(required: AccessLevel): boolean {
    const userLevel = activeUser?.accessLevel;
    if (!userLevel) return false;

    const userLevelIndex = accessLevels.indexOf(userLevel);
    const requiredLevelIndex = accessLevels.indexOf(required);
    return userLevelIndex <= requiredLevelIndex;
}

export function useIsSuperUser(): boolean {
    // return false;
    const me = useContext(ActiveUserContext);
    return me?.accessLevel === AccessLevel.Developer;
}
