import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import {
    Alert,
    AlertTitle,
    Button,
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { NetsTransactionQuery, NetsTransactionQueryVariables } from "../generated/gql/graphql";
import { Property, PropertyList } from "./ticket_v2/TicketPageHeader";
import { TimestampText } from "../widgets/date_time";
import { formatCustomerId, formatTicketId } from "../util/formatting";
import { formatMoney } from "./NetsTransactionsListPage";
import { TransactionNetsResponseText } from "./ticket_v2/TicketInfoTab";
import { isSuperUser } from "../util/active_user_context";
import ScrollablePageStructure from "../widgets/ScrollablePageStructure";
import LoadingScreen from "../widgets/LoadingScreen";
import { usePageTitle } from "../util/page_title";
import { SendReceiptEmailButton } from "./ticket_v2/SendReceiptEmailButton";
import { JsonDiagnosticButton } from "../widgets/diagnostics";
import { TraceButton } from "../widgets/TraceButton";

const NETS_TRANSACTION_QUERY = gql`
    query NetsTransaction($id: Int!) {
        netsTransaction(id: $id) {
            id
            netsTransactionId
            customer {
                id
                name
                phoneNumberFormatted
                createdTime
            }
            savedCard {
                id
                netsTransactionId
                issuer
                issuerId
                issuerCountry
                maskedPan
                netsTransactionId
                netsMerchantId
                netsMerchantEnvironment
                expirationDate
                cardTokenExpiredTime
                isActive
                createdTime
            }

            createdTime
            processedSuccessfullyTime
            amount
            amountRegistered
            amountCaptured
            canReleaseInvoices

            invoices {
                invoiceNumber
                ticket {
                    id
                    createdTime
                }
                descriptionNorwegian
                totalAmount
                createdTime
            }
            paymentReconciliation {
                trace {
                    traceId
                    spanId
                    sampled
                }
            }
            credits {
                id

                netsProcessParsedResponseCode
                netsProcessResponseCode
                netsProcessResponseSource
                netsProcessResponseText
                invoices {
                    invoiceNumber
                    ticket {
                        id
                        createdTime
                    }
                    descriptionNorwegian
                    totalAmount
                    createdTime
                }
                createdTime
            }

            netsCaptureParsedResponseCode
            netsCaptureResponseCode
            netsCaptureResponseSource
            netsCaptureResponseText
        }
    }
`;

type Transaction = NetsTransactionQuery["netsTransaction"];

function NetsTransactionPageHeader({ transaction }: { transaction: Transaction }) {
    const customer = transaction.customer;
    const savedCard = transaction.savedCard;

    return (
        <div>
            {!savedCard.isActive ? (
                <Alert>
                    <AlertTitle>Deaktivert!</AlertTitle>
                    Kunden byttet kort etter denne kortbetalingen.
                </Alert>
            ) : null}
            <h2>Kortbetaling: {transaction.netsTransactionId}</h2>

            <div style={{ marginBottom: "20px" }}>
                <Stack direction="row" spacing={2}>
                    <SendReceiptEmailButton transactionId={transaction.id} />
                    <TraceButton trace={transaction.paymentReconciliation?.trace} />
                </Stack>
            </div>

            <Grid container spacing={2} direction={"row"}>
                <Grid item xs={6} md={6}>
                    <Typography variant={"h6"} style={{ marginTop: "0" }}>
                        Betaling
                        <JsonDiagnosticButton name={"transaction"} value={transaction} />
                    </Typography>
                    <PropertyList>
                        <Property title={"Ordre-nummer"} value={transaction.id} />
                        <Property title={"Transaksjons-ID"} value={transaction.netsTransactionId} />
                        <Property title={"Beløp"} value={formatMoney(transaction.amount)} />
                        <Property
                            title={"Respons-kode"}
                            value={<TransactionNetsResponseText transaction={transaction} />}
                        />
                        <Property title={"Tidspunkt"} value={<TimestampText value={transaction.createdTime} />} />
                        <Property
                            requiresSuperUser
                            title={"amountRegistered"}
                            value={formatMoney(transaction.amountRegistered)}
                        />
                        <Property
                            requiresSuperUser
                            title={"amountCaptured"}
                            value={formatMoney(transaction.amountCaptured)}
                        />
                        <Property
                            requiresSuperUser
                            title={"processedSuccessfullyTime"}
                            value={<TimestampText value={transaction.processedSuccessfullyTime} />}
                        />
                        <Property
                            requiresSuperUser
                            title={"canReleaseInvoices"}
                            value={transaction.canReleaseInvoices}
                        />
                    </PropertyList>

                    <Typography variant={"h6"} style={{ marginTop: "30px" }}>
                        Kunde
                        <JsonDiagnosticButton name={"customer"} value={transaction.customer} />
                    </Typography>
                    <PropertyList>
                        <Property
                            title={"App-Id"}
                            value={<Link to={"/customer/" + customer.id}>{formatCustomerId(customer.id)}</Link>}
                        />
                        <Property title={"Mobilnummer"} value={customer.phoneNumberFormatted} />
                        {customer.name ? <Property title={"Navn"} value={customer.name} /> : null}
                        <Property title={"Registrert"} value={<TimestampText value={customer.createdTime} />} />
                    </PropertyList>
                </Grid>
                <Grid item xs={6} md={6}>
                    <Typography variant={"h6"} style={{ marginTop: "0" }}>
                        Kort
                        <JsonDiagnosticButton name={"savedCard"} value={savedCard} />
                    </Typography>
                    <PropertyList>
                        <Property requiresSuperUser title={"Id"} value={savedCard.id} />
                        <Property title={"Kortnummer"} value={savedCard.maskedPan} />
                        <Property title={"Utsteder"} value={savedCard.issuer} />
                        <Property title={"Utsteder-ID"} value={savedCard.issuerId} />
                        <Property title={"Utsteder land"} value={savedCard.issuerCountry} />
                        <Property title={"Utløpsdato"} value={savedCard.expirationDate} />
                        <Property requiresSuperUser title={"Nets merchant id"} value={savedCard.netsMerchantId} />

                        <Typography variant={"overline"} style={{ marginTop: "10px" }}>
                            Feltene nedenfor omhandler kort-registreringen.
                        </Typography>
                        <Property title={"Ordre-nummer"} value={savedCard.id} />
                        <Property title={"Nets transaksjons-id"} value={savedCard.netsTransactionId} />
                        <Property title={"Registrert"} value={<TimestampText value={savedCard.createdTime} />} />
                    </PropertyList>
                </Grid>
            </Grid>
        </div>
    );
}

const NetsTransactionPage = React.memo(() => {
    const params = useParams<{ transactionId: string }>();
    const transactionId = parseInt(params.transactionId!);
    const query = useQuery<NetsTransactionQuery, NetsTransactionQueryVariables>(NETS_TRANSACTION_QUERY, {
        variables: { id: transactionId },
    });

    usePageTitle("Kortbetaling - " + transactionId);

    if (query.loading) return <LoadingScreen />;

    const transaction = query.data!.netsTransaction;
    const invoices = transaction.invoices;

    return (
        <ScrollablePageStructure style={{ padding: "20px" }}>
            <NetsTransactionPageHeader transaction={transaction} />

            {isSuperUser() ? (
                <div>
                    <Button href={"/api/admin/nets/query?transactionId=" + transactionId}>NETS QUERY XML</Button>
                </div>
            ) : null}

            <Typography variant={"h6"} style={{ marginTop: "30px" }}>
                Fakturaer
            </Typography>
            <div style={{ width: "fit-content" }}>
                <Table width={"auto"}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Løpenummer</TableCell>
                            <TableCell>Billett-Id</TableCell>
                            <TableCell>Kjøpt</TableCell>
                            <TableCell>Beskrivelse</TableCell>
                            <TableCell>Faktura endret dato</TableCell>
                            <TableCell align={"right"}>Beløp</TableCell>
                            <TableCell align={"right"} />
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {invoices.map((invoice) => (
                            <TableRow key={invoice.invoiceNumber}>
                                <TableCell>{invoice.invoiceNumber}</TableCell>
                                <TableCell>
                                    <Link to={"/ticket2/" + invoice.ticket.id}>
                                        {formatTicketId(invoice.ticket.id)}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    <TimestampText value={invoice.ticket.createdTime} />
                                </TableCell>
                                <TableCell>{invoice.descriptionNorwegian}</TableCell>
                                <TableCell>
                                    <TimestampText value={invoice.createdTime} />
                                </TableCell>
                                <TableCell align={"right"}>{formatMoney(invoice.totalAmount)}</TableCell>
                                <TableCell align={"right"}>
                                    <JsonDiagnosticButton name={"invoice"} value={invoice} />
                                </TableCell>
                            </TableRow>
                        ))}
                        {transaction.credits.map((credit) => {
                            return credit.invoices.map((invoice) => {
                                return (
                                    <TableRow key={credit.id}>
                                        <TableCell>{invoice.invoiceNumber}</TableCell>
                                        <TableCell>
                                            <Link to={"/ticket2/" + invoice.ticket.id}>
                                                {formatTicketId(invoice.ticket.id)}
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            <TimestampText value={invoice.ticket.createdTime} />
                                        </TableCell>
                                        <TableCell>{invoice.descriptionNorwegian}</TableCell>
                                        <TableCell>
                                            <TimestampText value={invoice.createdTime} />
                                        </TableCell>
                                        <TableCell align={"right"}>{formatMoney(invoice.totalAmount)}</TableCell>
                                        <TableCell>
                                            <TransactionNetsResponseText transaction={credit} />
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            <JsonDiagnosticButton name={"credit"} value={credit} />
                                        </TableCell>
                                    </TableRow>
                                );
                            });
                        })}
                    </TableBody>
                </Table>
            </div>
        </ScrollablePageStructure>
    );
});

export { NetsTransactionPage };
