import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AccessLevel, AdministratorsQueryQuery, AdministratorsQueryQueryVariables } from "../generated/gql/graphql";
import LoadingScreen from "../widgets/LoadingScreen";
import React from "react";

const ADMINISTRATORS_QUERY = gql`
    query AdministratorsQuery {
        administrators {
            email
            accessLevel
        }
    }
`;

export function AdministratorsPage() {
    const query = useQuery<AdministratorsQueryQuery, AdministratorsQueryQueryVariables>(ADMINISTRATORS_QUERY);

    if (query.loading) return <LoadingScreen />;

    return (
        <div style={{ width: "100%", height: "100%", overflowY: "scroll" }}>
            <div
                style={{
                    width: "100%",
                    padding: "20px",
                    maxWidth: "500px",
                    margin: "40px auto",
                }}
            >
                <Typography variant={"h4"} style={{ marginBottom: "40px" }}>
                    Tilganger
                </Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>E-post</TableCell>
                                <TableCell>Tilgangs-nivå</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {query.data!.administrators.map((user) => (
                                <TableRow key={user.email}>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>
                                        <Tooltip title={<AccessLevelDescription level={user.accessLevel} />}>
                                            <span style={{ textDecorationStyle: "dotted" }}>{user.accessLevel}</span>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}

function AccessLevelDescription(props: { level: AccessLevel }) {
    let description = "";

    switch (props.level) {
        case AccessLevel.Developer:
            description = "Gir tilgang til rådata, som er nyttig for debugging";
            break;
        case AccessLevel.Project:
            description = "Kan se alle GPS-data vi har lagret i databasen";
            break;
        case AccessLevel.Support:
            description = "Kan kun se GPS-data fra brukere som har klaget på en reise";
            break;
    }

    return (
        <div>
            <Typography variant={"subtitle1"}>{props.level}</Typography>
            <Typography variant={"body2"}>{description}</Typography>
        </div>
    );
}
