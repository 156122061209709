import React from "react";
import "./App.css";
import { ApolloProvider } from "@apollo/client";
import Scaffold from "./widgets/Scaffold";
import { HashRouter, Route, Routes } from "react-router-dom";
import TicketPage from "./views/TicketPage";
import CustomerPage from "./views/CustomerPage";
import { NetsTransactionsListPage } from "./views/NetsTransactionsListPage";
import { NetsTransactionPage } from "./views/NetsTransactionPage";
import { StatisticsProcessingPage } from "./views/statistics/StatisticsProcessingPage";
import { FrontPage } from "./views/FrontPage";
import { TicketPageV2 } from "./views/TicketPageV2";
import { isSuperUser } from "./util/active_user_context";
import { createTheme, ThemeProvider } from "@mui/material";
import { ReportsPage } from "./views/ReportsPage";
import { HealthPage } from "./views/statistics/HealthPage";
import { TicketComplaintListPage } from "./views/TicketComplaintsListPage";
import { AuthorizedView } from "./widgets/AuthorizedView";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { createApolloClient } from "./util/graphql_client";
import { AdministratorsPage } from "./views/AdministratorsPage";

const theme = createTheme({
    palette: {
        primary: {
            main: "#333F48",
            contrastText: "#FFF",
        },
        secondary: {
            main: "#00AA50",
            contrastText: "#FFF",
        },
    },
    typography: {
        fontSize: 14,
    },
    components: {
        MuiTable: {
            defaultProps: {
                size: "small",
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: "filled",
            },
        },
        MuiTableCell: {
            defaultProps: {
                size: "small",
            },
        },
        MuiTableRow: {
            defaultProps: {
                hover: true,
            },
            styleOverrides: {
                hover: {
                    backgroundColor: "#DDD !important",
                },
            },
        },
    },
});

const client = createApolloClient();

export default function App() {
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <AuthorizedView>
                    <ApolloProvider client={client}>
                        <HashRouter>
                            <Scaffold>
                                <AdminPanel />
                            </Scaffold>
                        </HashRouter>
                    </ApolloProvider>
                </AuthorizedView>
            </LocalizationProvider>
        </ThemeProvider>
    );
}

function AdminPanel() {
    return (
        <Routes>
            <Route path={"/ticket/:ticketId/*"} element={<TicketPage />} />
            <Route path={"/ticket2/:ticketId/*"} element={<TicketPageV2 />} />
            <Route path={"/customer/:customerId/*"} element={<CustomerPage />} />

            <Route path={"/complaints"} element={<TicketComplaintListPage />} />
            <Route path={"/nets-transactions"} element={<NetsTransactionsListPage />} />
            <Route path={"/nets-transaction/:transactionId"} element={<NetsTransactionPage />} />
            <Route path={"/reports"} element={<ReportsPage />} />
            <Route path={"/administrators"} element={<AdministratorsPage />} />

            {isSuperUser() ? (
                <>
                    <Route path={"/statistics-processing"} element={<StatisticsProcessingPage />} />
                    <Route path={"/health"} element={<HealthPage />} />
                </>
            ) : null}

            <Route path={"/"} element={<FrontPage />} />
        </Routes>
    );
}
